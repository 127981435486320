.link-wrapper {
	display: flex;
	justify-content: center;
	margin-block-start: var(--link-with-icon-top-margin, var(--spacing-lg));
}

.link {
	--transition-duration: 0;

	display: inline-flex;
	align-items: center;
	gap: 0.5em;
}

.link > svg {
	block-size: 1.25rem;
	inline-size: 1.25rem;

	/* Adjust alignment to the link text */
	margin-top: -0.2em;

	transform: rotate(180deg);

	transition: transform var(--transition-duration);
}

@media (hover: hover) {
	.link:hover svg {
		transform: translateX(0.6rem) rotate(180deg);
	}
}

@media (--motion) {
	.link {
		--transition-duration: 0.2s;
	}
}
