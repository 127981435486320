.wrapper {
	display: flex;
	flex-direction: column;

	background-color: var(
		--split-section-background-color,
		var(--color-core-white)
	);

	color: var(--split-section-color, var(--color-core-black));
}

.text-column {
	padding: var(--spacing-lg) var(--spacing-sm);
}

.image {
	width: 100%;
}

.wrapper.image-align-v-bottom .image-column {
	order: 1;
}

.link-wrapper {
	display: flex;
	justify-content: flex-start;
	margin-block-start: 1.75rem;
}

.link {
	--transition-duration: 0;

	display: inline-flex;
	align-items: center;
	gap: 0.5em;
}

.link > svg {
	block-size: 1.25rem;
	inline-size: 1.25rem;

	/* Adjust alignment to the link text */
	margin-top: -0.2em;

	transform: rotate(180deg);

	transition: transform var(--transition-duration);
}

.image-wrapper {
	position: relative;
}

@media (--lg) {
	.wrapper {
		flex-direction: row;
		min-height: calc(100vh - var(--navigation-block-size));
	}

	.wrapper > * {
		width: 50%;
	}

	.image-column {
		position: relative;
	}

	.image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.link-wrapper {
		margin-block-start: var(--spacing-md);
	}

	.text-column {
		--stack-block-size: var(--spacing-md);

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: var(--spacing-lg);
	}

	.wrapper.image-align-h-left .image-column {
		order: 0;
	}

	.wrapper.image-align-h-right .image-column {
		order: 1;
	}
}

@media (hover: hover) {
	.link:hover svg {
		transform: translateX(0.6rem) rotate(180deg);
	}
}

@media (--motion) {
	.link {
		--transition-duration: 0.2s;
	}
}
