.headline {
	--stack-block-size: var(--spacing-sm);

	position: relative;

	padding: var(--hero-headline-padding, var(--spacing-lg) var(--spacing-sm));

	background-color: var(--hero-headline-background-color);

	text-align: center;
}

.content-with-visual {
	position: relative;
}

.tagline {
	margin-block-end: 3rem;
}

.visual {
	position: absolute;
	width: 1em;
	inset-block-start: 0.3em;
	inset-inline-start: 50%;

	transform: translateX(-50%);
}

.paragraph {
	max-width: 70rem;
	margin-inline: auto;
}

@media (--sm) {
	.heading {
		white-space: pre-line;
	}
}

@media (--lg) {
	.headline {
		--stack-block-size: var(--spacing-md);

		padding: var(--hero-headline-padding, var(--spacing-xl));
	}

	.content-wrapper {
		max-width: var(--hero-headline-max-width, calc(100rem + var(--spacing-xl)));
		margin: auto;
	}

	.tagline {
		margin-block-end: var(--spacing-md);
	}
}
