.links {
	margin-block-start: var(--spacing-md);
}

.links > li:not(:first-of-type) {
	margin-block-start: var(--spacing-sm);
}

.link {
	display: block;
	padding: var(--spacing-sm) var(--spacing-md);

	background-color: var(--background-color);

	color: var(--text-color);
}

@media (--md) {
	.links {
		display: flex;
		justify-content: center;
		gap: var(--spacing-sm);
	}

	.links > li {
		min-width: 18rem;
	}

	.links > li:not(:first-of-type) {
		margin-block-start: 0;
	}
}
