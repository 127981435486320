.wrapper {
	padding: var(--spacing-lg) var(--base-container-margins);

	text-align: center;
}

.wrapper > ul {
	margin-block-start: 3rem;
}

.wrapper p {
	max-width: 70rem;
	margin-inline: auto;
}
