.list {
	display: grid;

	grid-template-columns: repeat(var(--list-columns, 2), 1fr);
	max-width: calc(100rem + var(--spacing-sm));
	margin-block-start: var(--spacing-md);
	margin-inline: auto;

	gap: var(--spacing-md) var(--spacing-sm);
}

.image {
	display: block;
	width: 100%;
	margin-inline: auto;

	background-color: var(--color-core-light-grey);
}

.rounded {
	border-radius: 10% / calc(2 / 3 * 10%);
}

.header {
	margin-block-start: var(--spacing-md);

	text-align: center;
}

.subtitle {
	color: var(--color-core-dark-grey);
}

@media (--lg) {
	.list {
		margin-block-start: var(--spacing-lg);
	}

	.list.grid {
		--list-columns: 4;

		gap: var(--spacing-lg) var(--spacing-md);
	}

	.list.row {
		display: flex;
	}

	.list.row > * {
		flex: 1;
	}

	.list.row .header {
		margin-block-start: var(--spacing-sm);
	}
}
