.link-button {
	display: inline-block;
	padding: 0.75rem 1.125rem;

	background-color: var(--color-core-black);

	color: var(--color-core-white);
}

@media (--hover) {
	.link-button:hover {
		background-color: var(--color-core-dark-grey);

		color: var(--color-core-black);
	}
}

@media (--motion) {
	.link-button {
		transition: all 0.2s;
	}
}
