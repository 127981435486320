.list {
	max-width: calc(100rem + (2 * var(--spacing-lg)));
	margin-inline: auto;
	padding: var(--logo-links-padding, 0);

	text-align: center;
}

.buttons {
	width: 100%;
}

.logo {
	width: calc(var(--stockist-logo-size) * var(--stockist-logo-aspect-ratio));
	height: auto;
}

.list.grid {
	--stockist-logo-size: 5rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	gap: var(--spacing-xs) var(--spacing-sm);
}

.list.buttons {
	--stockist-logo-size: 2.25rem;
}

.list.buttons .item:not(:first-of-type) {
	margin-block-start: var(--spacing-sm);
}

.list.buttons .link {
	display: inline-block;
	width: 55%;
	padding: 1.5rem;

	border-radius: 1rem;

	background-color: var(
		--stockist-logo-background-color,
		var(--color-core-light-grey)
	);
}

@media (--md) {
	.list.grid {
		gap: var(--spacing-md);
	}
}

@media (--lg) {
	.list.grid {
		--stockist-logo-size: 7.5rem;
		gap: var(--spacing-lg);
	}

	.list.buttons {
		--stockist-logo-size: 3.75rem;
		display: flex;
		justify-content: center;
		gap: var(--spacing-md);
	}

	.list.buttons .item {
		width: 100%;
		max-width: 24rem;
	}

	.list.buttons .item:not(:first-of-type) {
		margin-block-start: 0;
	}

	.list.buttons .link {
		width: 100%;
		padding: var(--spacing-md);

		border-radius: 2rem;
	}
}

@media (--xxl) {
	.list.grid {
		--stockist-logo-size: 10rem;
		gap: 6rem;
	}
}
