.image-wrapper {
	position: relative;
	width: var(--list-item-image-width);
	height: calc(var(--list-item-image-width) / (4 / 5));
	overflow: hidden;

	border-radius: 1rem;
}

.image {
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.title {
	margin-block-start: var(--spacing-md);
}

.subtitle {
	margin-block-start: 0.25rem;

	color: var(--carousel-item-subtitle-color);
}
