@keyframes marquee {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

.wrapper {
	background-color: var(--marquee-background-color);

	text-align: center;
}

.title-padding {
	padding-block-start: var(--spacing-lg);
}

.marquee {
	--marquee-column-gap: var(--spacing-md);
	--marquee-logo-size: 4rem;
	--marquee-block-padding-medium: var(--spacing-sm);
	--marquee-block-padding-large: var(--spacing-lg);

	display: flex;
	justify-content: center;
	padding-block: var(--marquee-block-padding);

	overflow: hidden;
}

.title {
	text-transform: uppercase;
}

.logos {
	display: flex;
	gap: var(--marquee-column-gap);
}

.logos:not(:first-child) {
	padding-inline-start: var(--marquee-column-gap);
}

.logo {
	max-width: calc(var(--marquee-logo-size) * var(--marquee-logo-aspect-ratio));
	height: auto;
}

@media (--lg) {
	.marquee {
		--marquee-block-padding-medium: var(--spacing-md);
		--marquee-block-padding-large: 6rem;
		--marquee-column-gap: var(--spacing-lg);
		--marquee-logo-size: 5rem;
	}
}

@media (--motion) {
	.marquee.is-animated {
		--marquee-animation-duration: calc(
			var(--marquee-total-logo-width, 0) *
				var(--marquee-duration-per-pixel, 20ms)
		);
		--marquee-animation: marquee var(--marquee-animation-duration) linear
			infinite;

		justify-content: flex-start;
	}

	.marquee.is-animated .logos {
		padding-inline-start: var(--marquee-column-gap);

		animation: var(--marquee-animation);
	}
}
