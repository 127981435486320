.video-section {
	padding: var(--spacing-lg) var(--base-container-margins);

	background-color: var(--video-section-background-color, 'transparent');

	color: var(--video-section-text-color, 'inherit');
}

.content-wrapper {
	max-width: 70rem;
	margin-inline: auto;
}

.content-wrapper > h2 {
	white-space: pre;
}

.content-wrapper > p {
	margin-block-start: 1.5rem;
}

.cta {
	--link-with-icon-top-margin: var(--spacing-md);
	justify-content: flex-start;
}

.iframe-wrapper {
	position: relative;
	margin-block-start: var(--spacing-md);
}

.iframe-wrapper::before {
	content: '';

	display: block;
	padding-bottom: calc(100% / (var(--iframe-aspect-ratio)));
}

.iframe-wrapper > iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	border-style: none;
	border-radius: 1rem;
}

@media (--lg) {
	.video-section {
		padding-block: var(--spacing-lg);

		text-align: center;
	}

	.content-wrapper p {
		margin: var(--spacing-md) auto 0;
		padding-inline: var(--spacing-lg);
	}

	.cta {
		--link-with-icon-top-margin: 3rem;
		justify-content: center;
	}

	.iframe-wrapper {
		margin-block-start: var(--spacing-lg);
	}
}

@media (--xl) {
	.content-wrapper p {
		padding-inline: var(--spacing-xl);
	}
}
