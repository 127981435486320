.carousel-section {
	padding-block: var(--spacing-lg);

	background-color: var(--carousel-section-background-color);

	color: var(--carousel-section-color);
}

.carousel-section > div {
	margin-block-start: var(--spacing-md);
}

.header {
	--stack-block-size: 1.5rem;
	padding-inline: var(--base-container-margins);
}

.header > p {
	max-width: 34rem;
}

@media (--md) {
	.header > h2 {
		white-space: pre;
	}
}

.footer {
	padding-inline: var(--base-container-margins);
}

@media (--lg) {
	.carousel-section {
		padding-block: var(--spacing-xl);
	}

	.carousel-section > div {
		margin-block-start: var(--spacing-lg);
	}
}

@media (--xxl) {
	.header > p {
		width: 50%;
		max-width: none;
	}
}
