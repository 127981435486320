.full-bleed {
	width: 100%;
	height: calc(90vh - var(--navigation-block-size));
}

.full-bleed .image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contained {
	padding: var(--image-section-padding-top, 0) var(--base-container-margins)
		var(--image-section-padding-bottom, 0);
}

.contained .image {
	display: block;
	width: 100%;
	max-width: 70rem;
	margin-inline: auto;

	border-radius: 2rem;
}

@media (--lg) {
	.full-bleed {
		height: calc(100vh - var(--navigation-block-size));
	}
}
