.carousel {
	--list-item-image-width: 80vw;
	position: relative;
}

.scroller {
	overflow: auto hidden;
	scroll-snap-type: x mandatory;
	scroll-padding: auto var(--base-container-margins);
}

.list {
	display: flex;
}

/* hack to add a margin to the left and right which otherwise doesn’t work in safari */
.list::before,
.list::after {
	content: '';

	flex: 0 0 var(--base-container-margins, 0);
	height: 1px;
}

.nav-btn {
	display: block;
	position: absolute;
	top: calc((var(--list-item-image-width) * 1.25) * 0.42);
	width: 3rem;
	height: 3rem;

	background: var(--color-core-black);
}

.nav-btn[disabled] {
	display: none;
}

.nav-btn.next {
	right: 0;

	transform: rotate(180deg);
}

.nav-btn svg {
	width: var(--base-icon-size);

	fill: var(--color-core-white);
}

.item {
	scroll-snap-align: start;

	width: var(--list-item-image-width);
	padding-block-end: var(--spacing-lg);
}

.item:not(:last-of-type) {
	margin-inline-end: var(--spacing-sm);
}

@media (--md) {
	.carousel {
		--list-item-image-width: 40vw;
	}
}

@media (--lg) {
	.carousel {
		--list-item-image-width: 25vw;
	}

	.nav-btn {
		width: 5rem;
		height: 5rem;
	}

	.item:not(:last-of-type) {
		margin-inline-end: var(--spacing-md);
	}
}
